// src/App.js
import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import Header from './components/header/header';
import Contact from './components/contact/contact';
import BuyAndRent from './pages/buy&rent';
import Homepage from './pages/homepage';
import Footer from './components/footer/footer';
import PrivacyNotice from './pages/privacy_notice';
import RepairAndRestore from './pages/repair&restore';
import AnalyseAndDocument from './pages/analyse&document';
import Imprint from './pages/imprint';
import Disclaimer from './pages/disclaimer';
import CookiesDisclaimer from './components/cookies-disclaimer/cookies_disclaimer'; // Import the CookiesDisclaimer component
import './i18n';

function App() {
  const { i18n } = useTranslation();

  return (

    <Suspense fallback="Loading...">
      <Router>
        <div className="App">
          <CookiesDisclaimer /> 
          <Header />
          <main>

            <Helmet>
              <title>Geigenbau Jacobi – Tradition trifft Moderne</title>
              <meta charSet="utf-8" />
              <meta name="viewport" content="width=device-width, initial-scale=1" />
              <link rel="icon" href="/favicon.ico" />
              <meta property="og:type" content="website" />
              <meta property="og:title" content="Geigenbau Jacobi – Tradition trifft Moderne" />
              <meta property="og:description" content="Handgefertigte Geigen, Reparaturen und Restaurierungen auf höchstem Niveau." />
              <meta property="og:image" content="/og-image.jpg" />
              <meta property="og:url" content="https://www.geigenbau-jacobi.de" />
              <link rel="icon" href="../public/favicon.ico" />
            </Helmet>

            <Routes>
              <Route path="/" element={<Homepage />} />
              <Route path="/de/" element={<Homepage />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/buy&rent" element={<BuyAndRent />} />
              <Route path="/privacy_notice" element={<PrivacyNotice />} />
              <Route path="/repair&restore" element={<RepairAndRestore />} />
              <Route path="/analyse&document" element={<AnalyseAndDocument />} />
              <Route path="/imprint" element={<Imprint />} />
              <Route path="/disclaimer" element={<Disclaimer />} />
            </Routes>
          </main>
          <Footer />
        </div>
      </Router>
    </Suspense>
  );
}

export default App;
