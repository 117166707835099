// src/pages/homepage.js
import React from 'react';
import { Helmet } from 'react-helmet';
import LandingImage from '../components/landing_image/landing_image';
import Company_Display from '../components/company_display/company_display';

const HomePage = () => {
    return (
        <>
            <Helmet>
                <meta name="description" content="Hochwertige handgefertigte Geigen, Reparaturen und Restaurierungen in Geisenheim, Rheingau. Entdecken Sie unsere Expertise im Geigenbau." />
                <meta name="keywords" content="Geigenbau, Reparatur, Restaurierung, handgefertigte Geigen, Streichinstrumente" />
                <meta property="og:title" content="Geigenbau Jacobi – Tradition trifft Moderne" />
                <meta property="og:description" content="Erfahren Sie mehr über hochwertige handgefertigte Geigen und unsere umfassenden Serviceleistungen." />
                <meta property="og:url" content="https://www.geigenbau-jacobi.de" />
                <link rel="canonical" href="https://www.geigenbau-jacobi.de" />
            </Helmet>
            <div>
                <LandingImage />
                <Company_Display />
            </div>
        </>
    );
}

export default HomePage;
