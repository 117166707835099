import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook for translations
import './repair&restore.css';
import klangeinstellung from './images/klangeinstellung.jpg';
import reparatur from './images/reparatur.jpg';
import restauration from './images/restauration.jpg';

import enTranslations from './en.json'; // Import English translations
import deTranslations from './de.json'; // Import German translations

const RepairAndRestore = () => {
  const { t, i18n } = useTranslation(); // Translation hook
  const [headerHeight, setHeaderHeight] = useState(0);

  // Add translations for this component
  i18n.addResourceBundle('en', 'repairAndRestore', enTranslations, true, true);
  i18n.addResourceBundle('de', 'repairAndRestore', deTranslations, true, true);

  useEffect(() => {
    // Get the height of the header
    const header = document.querySelector('.header');
    if (header) {
      setHeaderHeight(header.offsetHeight);
    }
  }, []);

  const segmentStyle = {
    height: window.innerWidth > 1100 ? `calc(100vh - ${headerHeight}px)` : 'auto', // Set the height dynamically if screen width is larger than 1100px
  };

  return (
    <div className="repair-restore-container">
      <div className="segment" style={segmentStyle}>
        <img src={reparatur} alt="Reparatur" className="segment-image" />
        <div className="segment-content">
          <h1>{t('repairAndRestore:repair_title')}</h1>
          <p className="segment-text">{t('repairAndRestore:repair_text')}</p>
        </div>
      </div>

      <div className="segment" style={segmentStyle}>
        <img src={restauration} alt="Restauration" className="segment-image" />
        <div className="segment-content">
          <h1>{t('repairAndRestore:restoration_title')}</h1>
          <p className="segment-text">{t('repairAndRestore:restoration_text')}</p>
        </div>
      </div>

      <div className="segment" style={segmentStyle}>
        <img src={klangeinstellung} alt="Klangeinstellungen" className="segment-image" />
        <div className="segment-content">
          <h1>{t('repairAndRestore:sound_adjustment_title')}</h1>
          <p className="segment-text">{t('repairAndRestore:sound_adjustment_text')}</p>
        </div>
      </div>
    </div>
  );
};

export default RepairAndRestore;
