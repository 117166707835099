import React from 'react';
import './Contact.css';

const Contact = () => {
    return (
        <div className="contact-page">
            <div className="contact-info">
                <h1 className="contact-title">Geigenbau Andreas Jacobi</h1>
                <p className="contact-text">Bischof-Blum-Platz 9</p>
                <p className="contact-text">65366 Geisenheim</p>
                <p className="contact-text">Tel.: +49(0)6722-971982</p>
                <p className="contact-text">Whatsapp/WeChat/Signal: +49(0)17637100950</p>
                <p className="contact-text">kontakt@geigenbau-jacobi.de</p>
            </div>
        </div>
    );
};

export default Contact;
