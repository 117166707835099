import React from 'react';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook for translations
import './buy&rent.css';

import ViolinShop from './violin-shop.png';
import Streicherseite from './streicherseite.png';

import enTranslations from './en.json'; // Import English translations
import deTranslations from './de.json'; // Import German translations

const BuyAndRent = () => {
  const { t, i18n } = useTranslation(); // Translation hook

  // Add translations for this component
  i18n.addResourceBundle('en', 'buyAndRent', enTranslations, true, true);
  i18n.addResourceBundle('de', 'buyAndRent', deTranslations, true, true);

  return (
    <div className="buy-rent-page">
      <div className="buy-rent-content">
        <h1>{t('buyAndRent:rent_title')}</h1>
        <p className="rent-text" dangerouslySetInnerHTML={{ __html: t('buyAndRent:rent_text') }}></p>

        <a href="/contact" className="contact-button">
          {t('buyAndRent:contact_button')}
        </a>

        {/* New "Verkauf" Section */}
        <h1>{t('buyAndRent:sale_title')}</h1>
        <p className="verkauf-text" dangerouslySetInnerHTML={{ __html: t('buyAndRent:sale_text') }}></p>

        <div className="verkauf-container">
          <div className="verkauf-item">
            <a href="https://geigenbau-jacobi.myshopify.com">
              <img src={ViolinShop} alt="Link-Violin-Shop" className="verkauf-thumbnail" />
            </a>
          </div>
          <div className="verkauf-item">
            <a href="https://www.streichersaite.de">
              <img src={Streicherseite} alt="Link-Verkauf-Item" className="verkauf-thumbnail" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyAndRent;
